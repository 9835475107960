.main-dashboard{
    .second{
        margin-top: 45px;
    }
    .main-heading{
        margin-bottom: 25px;
        h4{
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 24px;
            text-transform: uppercase;
            color: #FFFFFF;
        }
    }
    .content-main{
        .heightsetcard{
            min-height: 180px;
        }
        .inner-card{
            background: #141516;
            border: 1px solid #1B1C1D;
            border-radius: 10px;
            margin-bottom: 20px;
            padding: 5px;
            min-height: 180px;
  
            .upper-head{
                background: #191A1B;
                border-radius: 8px;
                padding: 23px 20px;
                height: 66px;
                h6{
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 20px;
                    color: #FFFFFF;
                }
            }
            .lowerparadollar{
                padding: 0px 20px;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #8E8E8E;
            }
            .bottom-content{
                padding: 23px 20px 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .left{
                    p{
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 17px;
                        color: #8E8E8E;
                        margin-bottom: 8px;
                        display: flex;
                        align-items: center;
 
                    }
                    h6{
                        font-style: normal;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 22px;
                        color: #FFFFFF;
                        display: flex;
                        align-items: baseline;
                        .lowerparadollar{
                            padding: 0px 10px !important;
                            font-style: normal !important;
                            font-weight: 500 !important;
                            font-size: 12px !important;
                            line-height: 17px !important;
                            color: #8E8E8E !important;
                        }
                        span{
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 15px;
                            color: #BEBEBE;
                            display: flex;
                            align-items: center;
                            margin-left: 5px;
                        }

                    }
                }
            }
            .twice-inline{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .bottom-content{
                    flex: 1 0 48%;
                    // max-width: 312px;
                }
                .line{
                    height: 52px;
                    border: 1px solid rgba(255, 255, 255, 0.03);
                }
            }
        }
    }
    .second .upper-head{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}


@media (max-width:600px){
    .main-dashboard .main-heading h4{
        font-size: 20px;
    }
    .main-dashboard .content-main .inner-card .upper-head h6{
        font-size: 16px;
    }
    .main-dashboard .content-main .inner-card .twice-inline{
        flex-direction: column;
        justify-content: center;
    }
    .main-dashboard .content-main .inner-card .twice-inline .bottom-content{
       width: 100%;
    }
    .main-dashboard .content-main .inner-card .twice-inline .line{
        display: none;
    }
    .main-dashboard .content-main .inner-card .twice-inline .bottom-content{
        max-width: 100%;
    }
}

.welcome-modal-parent{
    .modal-dialog{
        max-width: 528px !important;
    }
}

.welcome-modal{
    margin: 22px 0;
    h6{
        color: #BEBEBE;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 100%; 
    }
}