.affliate {
  .top-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-content {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
    }

    h6 {
      color: #fff;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
    }

    .right-items {
      display: flex;
      align-items: center;
      gap: 15px;
      .selectDiv {
        select {
          background: transparent;
          border: none;
          outline: none;
        }
        display: flex;
        align-items: center;
        width: 81px;
      }
      .inner-field {
        border-radius: 6px;
        border: 1px solid #2b2c2e;
        background: #141516;
        padding: 8px 8px 8px 15px;
        display: flex;
        align-items: center;
        gap: 12px;

        .appdropdown {
          .btn {
            background-color: transparent;
            border: none;
            box-shadow: none;
            border-radius: 0;
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
            text-transform: capitalize;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 22px;
            padding: 19px;
            margin: 0;
            border-right: 1px solid #2b2c2e !important;
            padding-right: 10px;
            .rotate{
              transform: rotate(180deg);
              transition: 0.3s;
            }
            &:focus{
              .rotate{
            //  transform: rotate(0deg);
                         transition: 0.3s;
            }
            }
            &::after {
              display: none;
            }
          }
          .dropdown-menu {
            border-radius: 6px;
            border: 1px solid #2b2c2e;
            background: #191a1b;
            max-width: 96px;
            min-width: 96px;
            width: 96px;
            padding: 0;
            overflow: hidden;
            &:last-child{
              border-bottom: none;
            }
            .dropitem {
              color: #575757;
              text-align: center;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              border-bottom: 1px solid #1f1f1f;
              display: flex;
              padding: 12px 14px;
              align-items: flex-start;
              text-decoration: none;
                  &:last-child{
              border-bottom: none !important;
            }
              &:hover {
                border-bottom: 1px solid #1f1f1f;
                background: #1f1f1f;
                color: #fff;
              }
            }
          }
        }

        h6 {
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        .btn-copy {
          border-radius: 6px;
          background: var(--Primary-Blue, #1777e2);
          padding: 11px 14px;
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 100%;
        }
      }
    }

    .get-btn {
      border-radius: 6px;
      background: var(--Primary-Blue, #1777e2);
      border: none;
      padding: 11px 14px;
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
    }
  }

  .bottom-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 25px;

    .nav-tabs {
      border-bottom: 1px solid #191a1b;
      max-width: 352px;
      width: 100%;
      justify-content: space-between;
      flex-wrap: nowrap;

      .nav-item {
        flex: 1;

        .nav-link {
          width: 100%;
          // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          padding: 20px 34px 15px 34px;
          color: #575757;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          background-color: transparent;
          border: none;
          border-bottom: 1px solid transparent;
          border-radius: 0;
        }
      }
    }

    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
      border-bottom: 1px solid var(--Primary-Blue, #1777e2);
      // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      color: #1777e2;
      background-color: transparent;
    }
  }
}

.affliate-dashboard {
  .upper-cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;

    .single-card {
      flex: 0 0 32%;
      border-radius: 10px;
      border: 1px solid #1b1c1d;
      background: #141516;
      padding: 5px;
      height: 250px;

      .top-heading {
        border-radius: 8px;
        background: #191a1b;
        padding: 18px 20px;
        color: #fff;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 20px;
      }

      .bottom-text {
        padding: 0 10px;

        .text-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;

          h2 {
            color: #fff;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          p {
            color: #bebebe;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }

        .text {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 11px;

          &:last-child {
            margin-bottom: 0;
          }

          h6 {
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          p {
            color: #bebebe;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }

        .third-text {
          .upper-t {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
            margin-bottom: 10px;

            h6 {
              color: #fff;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }

            p {
              color: #bebebe;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }

          .para {
            color: #bebebe;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }

      .bottom-btn {
        width: 100%;

        .request-btn {
          border-radius: 6px;
          background: var(--Primary-Blue, #1777e2);
          padding: 11px 14px;
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 100%;
          display: block;
          width: 100%;
          text-align: center;
        }
      }

      .fourth-set-align {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        height: calc(100% - 85px);
      }
    }
  }

  .bottom-tables {
    margin-top: 25px;

    .top-heading {
      margin-bottom: 20px;

      h6 {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;
      }
    }

    .custom-table {
      border-radius: 10px;
      border: 1px solid #1b1c1d;
      background: #141516;
      padding: 5px;

      .upper-head {
        border-radius: 8px;
        background: #191a1b;
        padding: 21px 17px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;

        h6 {
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0.32px;
          flex: 1;

          &:last-child {
            text-align: right;
          }
        }
      }

      .bottom-body {
        .trow {
          border-radius: 8px;
          border: 1px solid #1e1f20;
          background: #141516;
          padding: 21px 17px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          p {
            color: #fff;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            flex: 1;

            &:last-child {
              text-align: right;
            }
          }
        }
      }
    }
  }

  .displayblockformobile .top-heading {
    margin-top: 25px;
    margin-bottom: 15px;

    h6 {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .bottom-tables-mobile {
    border-radius: 10px;
    border: 1px solid #1b1c1d;
    background: #141516;
    padding: 5px;

    .inner-detail {
      border-radius: 8px;
      border: 1px solid #1e1f20;
      background: #141516;
      padding: 15px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      .text {
        flex: 0 0 45%;

        p {
          color: #949494;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 14px;
        }

        h6 {
          color: #fff;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }
}

.request-withdraw-modal {
  fill: rgba(7, 7, 7, 0.7);
  backdrop-filter: blur(2px);

  .modal-dialog {
    max-width: 451px;
  }

  .modal-content {
    border-radius: 10px;
    border: 1px solid #1b1c1d;
    background: #141516;
    padding: 5px;
  }

  .modal-header {
    border-radius: 8px;
    background: #191a1b;
    padding: 18px 15px;
    border: none;
    justify-content: center;

    .modal-title {
      color: #fff;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 0;
    }

    .btn-close {
      position: absolute;
      top: 25px;
      right: 18px;
      background: url("../../../src/assets/img/close-circle.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      opacity: 1;
    }
  }

  .modal-body {
    padding: 18px 15px;

    .inner-content {
      h6 {
        color: #bebebe;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .bottom-tabs {
      margin-top: 27px;

      .nav-tabs {
        justify-content: space-between;
        border-bottom: 1px solid #191a1b;
        background: #141516;
        margin-bottom: 27px;

        .nav-item {
          .nav-link {
            width: 100%;
            color: #575757;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            border: none;
            border-bottom: 1px solid transparent;
          }
        }
      }

      .nav-tabs .nav-link.active,
      .nav-tabs .nav-item.show .nav-link {
        border-bottom: 1px solid var(--Primary-Blue, #1777e2);
        background-color: transparent;
      }

      .inner-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 22px;
        border-radius: 10px;
        background: #191a1b;
        padding: 20px 10px;

        .left-text {
          h6 {
            color: #fff;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;

            span {
              color: #bebebe;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }

          p {
            color: #525252;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }

        .plus-sign {
          color: #fff;
        }

        .right-text {
          h6 {
            color: #fff;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;

            span {
              color: #bebebe;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }

          p {
            color: #525252;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
    }

    .bottom-btns {
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        flex: 0 0 48%;
        padding: 16px 25px;
      }

      .btn-cancel {
        border-radius: 6px;
        border: 1px solid #2c2c2c;
        background: #141516;
        color: var(--Primary-Blue, #1777e2);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
      }

      .btn-withdraw {
        border-radius: 6px;
        background: var(--Primary-Blue, #1777e2);
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        border: none;
      }
    }
  }

  .request-completed {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h6 {
      color: #bebebe;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 27px;
      margin-bottom: 22px;
    }

    .btn-okay {
      border-radius: 6px;
      background: var(--Primary-Blue, #1777e2);
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      border: none;
      padding: 16px 25px;
      width: 100%;
    }
  }

  .bottom-refferallink {
    label {
      color: #bebebe;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
    }

    h6 {
      border-radius: 6px;
      border: 1px solid #2b2c2e;
      background: #232526;
      padding: 8px 8px 8px 15px;
      overflow-x: auto;
      margin-bottom: 22px;
      white-space: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.affliate-history {
  .upper-filters {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;

    .dropdown {
      .dropdown-toggle {
        border-radius: 6px;
        border: 1px solid var(--Primary-Blue, #1777e2);
        background: #111213;
        padding: 8px 15px;
        color: #414141;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;

        &::after {
          display: none;
        }
      }

      .dropdown-menu {
        border-radius: 6px;
        border: 1px solid #2b2c2e;
        background: #191a1b;
        padding: 0;

        a {
          padding: 12px 14px;
          color: #575757;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          transition: 0.3s linear;

          &:hover {
            background: #1f1f1f !important;
            color: #fff;
          }
        }
      }
    }
  }

  .custom-table {
    border-radius: 10px;
    border: 1px solid #1b1c1d;
    background: #141516;
    padding: 5px;

    .set-specific-width {
      width: 200px;
    }

    .upper-head {
      border-radius: 8px;
      background: #191a1b;
      padding: 21px 17px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;

      h6 {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.32px;
      }
    }

    .bottom-body {
      .trow {
        border-radius: 8px;
        border: 1px solid #1e1f20;
        background: #141516;
        padding: 21px 17px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        .twice-text {
          .date-text {
            color: #949494;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-align: start;
          }
        }
      }
    }
  }
}

.search-affliate {
  .parent {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 733px;
    width: 100%;
    gap: 15px;

    .option-field {
      position: relative;
      width: 80%;
      z-index: 99;

      input {
        border-radius: 6px;
        border: 1px solid #2b2c2e;
        background: #141516;
        padding: 8px;
        padding-left: 40px;
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 100%;
        height: 52px;

        &::placeholder {
          color: #575757;
        }
      }

      .search-normal {
        position: absolute;
        top: 15px;
        left: 12px;
        pointer-events: none;
      }

      .dropdown {
        position: absolute;
        top: 50%;
        right: 0%;
        transform: translate(0%, -50%);

        .dropdown-toggle {
          background-color: transparent;
          border: none;
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 100%;
          padding: 11px 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;

          &::after {
            display: none;
          }
        }

        .dropdown-menu {
          border-radius: 6px;
          border: 1px solid #2b2c2e;
          background: #191a1b;
          padding: 0;

          a {
            padding: 12px 14px;
            color: #575757;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            transition: 0.3s linear;

            &:hover {
              background: #1f1f1f !important;
              color: #fff;
            }
          }
        }
      }
    }

    .btn-search {
      border-radius: 6px;
      background: var(--Primary-Blue, #1777e2);
      padding: 12px 14px;
      border: none;
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      width: 18%;
      height: 50px;
    }
  }

  .bottom-fields {
    margin-top: 25px;
    border-radius: 10px;
    border: 1px solid #1b1c1d;
    background: #141516;
    width: 51%;
    padding: 15px;

    .upper-field {
      border-radius: 8px;
      background: #191a1b;
      padding: 15px 10px;

      h6 {
        color: #fff;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;
      }
    }

    .bottom-text {
      .text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0;

        h6 {
          color: #fff;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        p {
          color: #bebebe;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 4px;

          span {
            color: #bebebe;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
    }
  }
}

.firsttimeuser {
  h6 {
    color: #bebebe;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 10px;
  }

  .option-field {
    position: relative;
    margin-top: 26px;

    label {
      color: #bebebe;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      display: block;
    }

    .twice-field {
      border-radius: 6px;
      border: 1px solid #2b2c2e;
      background: #141516;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
      height: 52px;
      padding: 8px;
      padding-left: 15px;

      input {
        background-color: transparent;
        border: none;
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        &::placeholder {
          color: #575757;
        }
      }

      .dropdown {
        .dropdown-toggle {
          background-color: transparent;
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          border: none;
          max-width: 133px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;

          &::after {
            display: none;
          }
        }

        .dropdown-menu {
          border-radius: 6px;
          border: 1px solid #2b2c2e;
          background: #191a1b;
          padding: 0;

          a {
            padding: 12px 14px;
            color: #575757;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            transition: 0.3s linear;

            &:hover {
              background: #1f1f1f !important;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .option-field1 {
    margin-bottom: 22px;

    input {
      border-radius: 6px;
      border: 1px solid #2b2c2e;
      background: #141516;
      padding: 8px;
      padding-left: 15px;
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      width: 100%;
      height: 52px;

      &::placeholder {
        color: #575757;
      }
    }

    .has-text {
      border: 1px solid var(--Red, #c93636) !important;
    }
    .has-text-success {
      border: 1px solid var(--Red, #50bf29) !important;
    }

    .btn-checkk {
      border-radius: 6px;
      background: var(--Primary-Blue, #1777e2);
      padding: 11px 14px;
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      position: absolute;
      top: 66%;
      right: 15px;
      transform: translate(0, -50%);
      border: none;
    }

    .close-red {
      position: absolute;
      top: 50%;
      right: 95px;
      transform: translate(0, -50%);
    }
  }

  .inner-filed {
    border-radius: 6px;
    border: 1px solid #2b2c2e;
    background: #141516;
    padding: 8px;
    padding-left: 15px;
    margin-top: 15px;
    margin-bottom: 22px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
      .appdropdown {
          .btn {
            background-color: transparent;
            border: none;
            box-shadow: none;
            border-radius: 0;
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
            text-transform: capitalize;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 22px;
            padding: 19px;
            margin: 0;
            border-right: 1px solid #2b2c2e !important;
            padding-right: 10px;
            .rotate{
              transform: rotate(180deg);
              transition: 0.3s;
            }
            &:focus{
              .rotate{
            //  transform: rotate(0deg);
                         transition: 0.3s;
            }
            }
            &::after {
              display: none;
            }
          }
          .dropdown-menu {
            border-radius: 6px;
            border: 1px solid #2b2c2e;
            background: #191a1b;
            max-width: 96px;
            min-width: 96px;
            width: 96px;
            padding: 0;
            overflow: hidden;
            &:last-child{
              border-bottom: none;
            }
            .dropitem {
              color: #575757;
              text-align: center;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              border-bottom: 1px solid #1f1f1f;
              display: flex;
              padding: 12px 14px;
              align-items: flex-start;
              text-decoration: none;
                  &:last-child{
              border-bottom: none !important;
            }
              &:hover {
                border-bottom: 1px solid #1f1f1f;
                background: #1f1f1f;
                color: #fff;
              }
            }
          }
        }

    h5 {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      white-space: nowrap;
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 10px;
    }
  }
}

@media (max-width: 991px) {
  .affliate .top-content {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
  }

  .affliate .top-content .right-items .inner-field h6 {
    font-size: 12px;
    width: 200px;
    word-break: break-all;
  }

  .affliate .bottom-content {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
  }

  .affliate-dashboard .upper-cards {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }

  .affliate-dashboard .upper-cards .single-card {
    flex: 0 0 100%;
    width: 100%;
    height: auto;
  }

  .affliate-dashboard .bottom-tables .top-heading {
    margin-top: 30px;
  }

  .affliate-dashboard .upper-cards .single-card .bottom-btn .request-btn {
    margin-top: 20px;
  }

  .request-withdraw-modal .modal-body .bottom-tabs .nav-tabs {
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .request-withdraw-modal .modal-body .bottom-tabs .nav-tabs .nav-item .nav-link {
    white-space: nowrap;
  }

  .request-withdraw-modal .modal-body .bottom-tabs .nav-tabs .nav-link.active,
  .request-withdraw-modal .modal-body .bottom-tabs .nav-tabs .nav-item.show .nav-link {
    border-bottom: 2px solid #1777e2;
  }

  .request-withdraw-modal .modal-body .inner-content h6 {
    font-size: 14px;
  }

  .request-withdraw-modal .modal-body .bottom-tabs .inner-text {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
  }

  .affliate-history .upper-filters {
    justify-content: space-between;
  }

  .affliate-history .upper-filters .dropdown {
    width: 49%;
  }

  .affliate-history .upper-filters .dropdown .dropdown-toggle {
    width: 100%;
  }

  .affliate-history .custom-table {
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .affliate-history .custom-table .set-specific-width {
    width: 400px;
  }

  .affliate-history .custom-table .upper-head {
    width: 1000px;
  }

  .affliate-history .custom-table .bottom-body .trow {
    width: 1000px;
  }

  .search-affliate .parent {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .search-affliate .parent .option-field {
    width: 100%;
  }

  .search-affliate .parent .btn-search {
    width: 100%;
  }

  .search-affliate .bottom-fields {
    width: 100%;
  }

  .search-affliate .bottom-fields .upper-field h6 {
    font-size: 16px;
  }

  .search-affliate .bottom-fields .bottom-text .text h6 {
    font-size: 16px;
  }

  .search-affliate .bottom-fields .bottom-text .text p {
    font-size: 16px;
  }

  .affliate .bottom-content .nav-tabs .nav-item .nav-link {
    padding: 10px;
  }

  .displayblockformobile {
    display: block !important;
  }

  .displaynoneformobile {
    display: none;
  }
}

.textLowerCase {
  text-transform: lowercase !important;
}
.disable {
  cursor: not-allowed !important;
  filter: brightness(0.5);
}
.loading {
  cursor: progress !important;
  filter: brightness(0.5);
}

.awaiting-approval-modal {
  .modal-dialog {
    max-width: 1040px !important;
  }

  .modal-body {
    padding: 18px 0 !important;
  }

  .custom-table {
    border-radius: 10px;
    border: 1px solid #1b1c1d;
    background: #141516;
    padding: 5px;

    .upper-head {
      border-radius: 8px;
      background: #191a1b;
      padding: 21px 17px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;

      .widthsmall {
        width: 17.5%;
      }

      .widthlarge {
        width: 30%;
      }

      h6 {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.32px;
        white-space: nowrap;

        &:last-child {
          text-align: center;
        }
      }
    }

    .bottom-body {
      .trow {
        border-radius: 8px;
        border: 1px solid #1e1f20;
        background: #141516;
        padding: 21px 17px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .widthsmall {
          width: 17.5%;
        }

        .widthlarge {
          width: 30%;
        }

        p {
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          flex: 1;
          white-space: nowrap;
        }
      }
    }
  }
  .status-orange {
    color: #e29d17 !important;
  }
  .status-green {
    color: #50bf29 !important;
  }
  .status-red {
    color: #c93636 !important;
  }
  .status-blue {
    color: #1777e2 !important;
  }
  .awaiting-approval-modal .custom-table .upper-head {
    gap: 25px !important;
  }
  .awaiting-approval-modal .custom-table .bottom-body .trow {
    gap: 25px !important;
  }
  .btn-claim {
    border-radius: 6px;
    background: var(--Primary-Blue, #1777e2);
    padding: 13px 25px;
    border: none;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
  }
}

@media (max-width: 1040px) {
  .awaiting-approval-modal .custom-table .bottom-body .trow {
    gap: 25px !important;
    // overflow-x: auto;
  }
}

@media (max-width: 600px) {
  .awaiting-approval-modal .custom-table .upper-head h6 {
    white-space: nowrap;
  }
  .awaiting-approval-modal .custom-table .upper-head {
    gap: 25px !important;
  }
  .awaiting-approval-modal .custom-table .bottom-body .trow p {
    white-space: nowrap !important;
  }
  .hcvttvdvctvcv {
    overflow-x: auto;
  }
  // .hcvttvdvctvcv .custom-table .upper-head{
  //   gap: 120px !important;
  // }

  // .hcvttvdvctvcv .custom-table .bottom-body .trow{
  //   gap: 120px !important;
  // }
  .awaiting-approval-modal .btn-claim {
    white-space: nowrap;
  }
  .awaiting-approval-modal .custom-table {
    overflow-x: auto;
    width: 1200px;
  }
  .awaiting-approval-modal .custom-table .upper-head .widthsmall {
    width: 55.5%;
  }
  .awaiting-approval-modal .custom-table .bottom-body .trow .widthsmall {
    width: 55.5%;
  }
  .awaiting-approval-modal .custom-table .upper-head h6:last-child {
    text-align: start !important;
  }
  .awaiting-approval-modal .custom-table .upper-head h6 {
    width: 55.5%;
  }
  .awaiting-approval-modal .custom-table .bottom-body .trow p {
    width: 55.5%;
  }
  .firsttimeuser .inner-filed h5 {
    word-break: break-all;
  }
}

.request-withdraw-modal .dfaf {
  top: 50% !important;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
