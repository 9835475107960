.main-earn {
    .main-heading {
        margin-bottom: 25px;

        h4 {
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 24px;
            text-transform: uppercase;
            color: #FFFFFF;
        }
    }

    .left-content {
        background: #141516;
        border: 1px solid #1B1C1D;
        border-radius: 10px;
        padding: 5px;
        padding-bottom: 25px;

        .upper-head {
            background: rgba(255, 255, 255, 0.02);
            border-radius: 8px;
            padding: 23px 20px;

            h6 {
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 20px;
                color: #FFFFFF;
            }
        }

        .time-select {
            padding: 5px;
            margin-bottom: 25px;
            width: 100%;


            .yysyvsyvyxs {
                background-color: transparent;
                border: none;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 15px;
                text-align: center;
                color: #575757;
                white-space: nowrap;
                padding: 20px 22px 15px;
                border-bottom: 1px solid #191A1B;
                flex: 0 0 33.3%;

                // &:active {
                //     background-color: transparent;
                //     border: none;
                //     border-bottom: 1px solid #1777E2 !important;
                // }
            }

            .active-btn {
                border: none;
                border-bottom: 1px solid #1777E2 !important;
                border-radius: 0;
                color: #FFFFFF;
                background-color: transparent;

            }

        }

        .option-field {
            padding: 0 20px;
            margin-bottom: 25px;

            .upper-area {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 12px;

                label {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 15px;
                    color: #FFFFFF;
                }

                h6 {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 15px;
                    color: #FFFFFF;

                    span {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 15px;
                        color: #575757;
                    }
                }
            }

            .input-field {
                position: relative;

                input {
                    background: #141516;
                    border: 1px solid #2B2C2E;
                    border-radius: 6px;
                    padding: 18.5px 16px;
                    width: 100%;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 15px;
                    color: #fff;
                    transition: 0.3s linear;

                    &::placeholder {
                        color: #414141;
                    }

                    &:focus {
                        border: 1px solid #1777E2;
                    }

                    &:hover {
                        border: 1px solid #153C68;
                    }
                }

                button {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 100%;
                    color: #FFFFFF;
                    padding: 11px 14px;
                    background: #1777E2;
                    border-radius: 6px;
                    border: none;
                    position: absolute;
                    top: 8.5px;
                    right: 10px;
                    transition: 0.3s linear;

                    &:hover {
                        background: #0076F9;
                        box-shadow: 0px 4px 9px rgba(23, 119, 226, 0.2);
                        border-radius: 6px;
                    }
                }
            }
        }

        .slide-ranger {
            padding: 0 20px;

            .slider {
                -webkit-appearance: none;
                width: 100%;
                height: 10px;
                background: #191A1B;
                border-radius: 50px;
                outline: none;
                opacity: 0.7;
                -webkit-transition: .2s;
                transition: opacity .2s;
            }

            .slider:hover {
                opacity: 1;
            }

            .slider::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background: #1777E2;
                cursor: pointer;
            }

            .slider::-moz-range-thumb {
                width: 24px;
                height: 24px;
                background: #1777E2;
                cursor: pointer;
            }

            .inner-value {
                margin-top: 8px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                span {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 100%;
                    color: #575757;
                }
            }
        }

        .bottom-text {
            margin-top: 28px;
            padding: 0 20px;

            .text {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 0;
                }

                .maintext {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 5px;
                    position: relative;

                    h6 {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 17px;
                        color: #949494;
                    }

                    .hoverdiv {
                        display: none;
                        background-color: #313131;
                        z-index: 9;
                        padding: 5px;
                        border-radius: 10px;
                        font-size: 12px;
                        color: #FFF;
                        position: absolute;
                        top: 30px;
                        right: -50%;
                        transform: translate(50%,0);
                        width: 180px;
                    }

                    .questionimg {
                        cursor: pointer;
                        &:hover{
                            ~.hoverdiv {
                                display: block !important;
                            }
                        }
                    }
                }

                h6 {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 17px;
                    color: #949494;
                }

                h5 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 17px;
                    color: #FFFFFF;

                    span {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 15px;
                        color: #BEBEBE;
                    }
                }
            }
        }

        .btn-approve {
            margin-top: 25px;
            margin-bottom: 20px;
            padding: 0 20px;

            button {
                background: #1777E2;
                border-radius: 6px;
                border: none;
                padding: 16px;
                width: 100%;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 100%;
                color: #FFFFFF;
                transition: 0.3s linear;

                &:hover {
                    background: #0076F9;
                    box-shadow: 0px 4px 9px rgba(23, 119, 226, 0.2);
                    border-radius: 6px;
                }
            }
        }

        .bottom-progress {
            display: flex;
            justify-content: center;
            align-items: center;

            .activespan {
                background: #1777E2;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 100%;
                color: #FFFFFF;
            }

            .unactivespan {
                background-color: transparent !important;
                border: 2px solid #191A1B;
            }
        }
    }

    .right-content {
        background: #141516;
        border: 1px solid #1B1C1D;
        border-radius: 10px;
        padding: 5px;

        .upper-head {
            background: rgba(255, 255, 255, 0.02);
            border-radius: 8px;
            padding: 7px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            h6 {
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 20px;
                color: #FFFFFF;
            }
        }

        .bottom-content {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .itemss {
                // flex: 1 0 20%;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
                padding: 20px;

                h6 {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 15px;
                    color: #8E8E8E;
                    margin-bottom: 8px;
                }

                h5 {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 17px;
                    color: #FFFFFF;
                    white-space: nowrap;
                    margin-bottom: 8px;

                    span {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 15px;
                        color: #BEBEBE;
                    }
                }

                .hide-btn {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 15px;
                    color: #1777E2;
                    display: flex;
                    align-items: center;
                }

                a[aria-expanded="false"] .hid {
                    display: none;
                }

                a[aria-expanded="true"] .dt {
                    display: none;
                }

                a[aria-expanded="true"] img {
                    transform: rotate(180deg);
                }


                .btn-disable {
                    background: #191B1D !important;
                    border: 1px solid #2C2C2C !important;
                    border-radius: 6px !important;
                    pointer-events: none;
                    color: #303234 !important;
                }
            }
        }

        .bottom-content1 {
            background: #141516;
            border: 1px solid #1E1F20;
            border-radius: 8px;
            margin-top: 5px;
        }

        .bottom-content2 {
            background: #171819;
            border: 1px solid #1E1F20;
            border-radius: 0 0 8px 8px;
            margin-top: 5px;
        }
    }

    .MuiSlider-root {
        color: #191A1B;
        border-radius: 50px;
    }

    .MuiSlider-rail {
        height: 10px;
    }

    .MuiSlider-thumb {
        width: 24px;
        height: 24px;
        background-color: #1777E2 !important;
    }

    .MuiSlider-track {
        height: 10px;
    }


}

@media (max-width:600px) {
    .main-earn .main-heading h4 {
        font-size: 20px;
    }

    .main-earn .left-content .upper-head h6 {
        font-size: 16px;
    }

    .main-earn .right-content {
        margin-top: 25px;
    }

    .main-earn .right-content .bottom-content {
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .main-earn .right-content .bottom-content .itemss h6 {
        font-size: 12px;
    }

    .main-earn .right-content .bottom-content .itemss h5 {
        font-size: 14px;
    }

    .main-earn .right-content .bottom-content .itemss h5 span img {
        margin: 0 !important;
    }

    .main-earn .right-content .bottom-content .itemss {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        padding: 20px;
        flex: 0 1 40%;
    }

    .main-earn .right-content .bottom-content .itemss-sm {
        flex: 1 1 100%;
        justify-content: center;
        align-items: center;
    }

    .main-earn .right-content .bottom-content2 {
        background: #191A1B;
    }

    .main-earn .right-content .bottom-content2 .btn-transparent {
        width: 100%;
    }

    .main-earn .right-content .bottom-content2 .itemss {
        flex: 0 1 49%;
    }

    .main-earn .btn-transparent {
        font-size: 14px;
        background-color: transparent;
    }
}

.pagination-custom {
    margin-top: 45px;
    display: flex;
    align-items: center;
    justify-content: center;

    .left {
        background: #141516;
        border: 1px solid #272727;
        border-radius: 8px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
    }

    .right {
        background: #141516;
        border: 1px solid #272727;
        border-radius: 8px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 15px;

        &:hover {
            background: #1777E2;
        }
    }

    .inner-values {
        display: flex;
        justify-content: center;
        align-items: center;

        .spec {
            background: #141516;
            border: 1.5px solid #1777E2;
            border-radius: 8px;
            width: 24px;
            height: 24px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 17px;
            color: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .total {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 17px;
            color: #FFFFFF;
        }

        span {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 17px;
            color: #FFFFFF;
            margin: 0 10px;
        }
    }
}

.sytvcstsc {
    background-color: transparent;
    color: #1777E2;
    border: none;
}
.sytvcstscdfd{
    font-style: normal;
                    font-weight: 500;
 color: #1777E2;
 cursor: pointer;
 font-size: 18px;
 z-index: 999;
     font-family: 'Archivo', sans-serif !important;
     position: relative;
     top: -10px;
}

.load-btn {
    background: #1777E2;
    border-radius: 6px;
    border: none;
    padding: 16px;
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #FFFFFF;
    transition: 0.3s linear;
    display: block;
    margin: 0 auto;
    max-width: 200px;
    margin-top: 40px;
}

.parent-items {
    max-height: 580px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
        display: none;
    }
}

.css-1hskriy {
    width: 100% !important;
}


.css-13i4rnv-MuiGrid-root {
    display: none;
}

.deposts {
    color: white;
    text-align: center;
}

.proBar {
    width: 130px;
}

.main-earn .progress {
    height: 6px !important;
    background: #1F2021;
    border-radius: 50px;
}

.progress-bar {
    background: #1777E2;
    border-radius: 50px;
}