@import "~bootstrap/scss/bootstrap";
@import 'react-date-range/dist/styles.css'; 
@import 'react-date-range/dist/theme/default.css';

.cursor-pointer {
    cursor: pointer;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.rounded-full {
    border-radius: 50%;
}

.ml-2x {
    margin-left: 2px;
}

.ml-6x {
    margin-left: 6px;
}

// .btn-common-pur:hover {
//     opacity: 0.75;
//     color: white;
// }

iframe {
    position: unset !important;
    top: unset !important;
    left: unset !important;
    width: unset !important;
    height: unset !important;
    border: unset !important;
    z-index: unset !important;
}

.ml-15 {
    margin-left: 15px;
}

.offcanvas {
    background: #1e1e1e;
    z-index: 99999;
}
.offcanvas.offcanvas-start {
    left: unset !important;
    right: 0;
    transform: translateX(0) !important;
}
.offcanvas-backdrop.show {
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(3px);
    opacity: 1;
}
.offcanvas-header .btn-close {
    background: url("./close-circle.svg");
    background-size: cover;
    opacity: 1;
}
.btn-close:focus {
    box-shadow: none !important;
}
.navbar-toggler:focus {
    box-shadow: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.btn:focus,
.btn.focus {
    box-shadow: none !important;
}

.table-responsive {
    &::-webkit-scrollbar {
        display: none !important;
    }
}
body {
    --wcm-z-index: 9999 !important;
}
.offcanvas-body {
    &::-webkit-scrollbar {
        display: none !important;
    }
}

.customerdetail .table-customer {
    &::-webkit-scrollbar {
        display: none !important;
    }
}
.customer .table-customer {
    &::-webkit-scrollbar {
        display: none !important;
    }
}
.promo .table-customer {
    &::-webkit-scrollbar {
        display: none !important;
    }
}
.offcanvas,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm,
.offcanvas-xl,
.offcanvas-xxl {
    --bs-offcanvas-width: 470px !important;
}

@media (max-width: 600px) {
    .offcanvas,
    .offcanvas-lg,
    .offcanvas-md,
    .offcanvas-sm,
    .offcanvas-xl,
    .offcanvas-xxl {
        --bs-offcanvas-width: 100% !important;
    }
    .padd-sm {
        padding: 0 !important;
    }
    .ml-15 {
        margin-left: 0 !important;
    }
    .metaMobile {
        display: none !important;
    }
}

.wcm-overlay {
    z-index: 99999999999 !important;
}
.wcm-active {
    pointer-events: auto;
    position: relative !important;
    z-index: 999999999 !important;
}



// new ui scss here..................................

.custom-tab-bar {
    border-radius: 5px;
    border: 1px solid #1B1C1D;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 331px;
    width: 100%;
    position: relative;
    height: 46px;
    a {
      padding: 6px 11px;
      color: #A2A4A6;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      text-align: center;
      width: 100%;
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &.active {
        border-radius: 5px;
        background: #1777E2;
        color: #fff;
        padding: 11px;
      }
    }
   .set-custom-calendar-div {
        position: absolute;
        top: 0;
        left: 0;
    }
  }
  
  .cal .rdrCalendarWrapper {
    position: absolute;
    top: 50px;
    z-index: 99;
    background: #111213;
    border-radius: 0;
}
.cal .rdrDateDisplayWrapper {
    display: none;
}
.cal .rdrDayNumber{
    color: #fff;
}
.cal .rdrDayNumber span{
    color: #fff;
}

.cal button{
    border: none !important;
    color: #f2f2f2 !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.32px;
    background-color: transparent;
    padding: 10px 16px;
}
  
.imainimg{
    margin-left: 10px;
    z-index: 9999;
    &:hover{
        path{
            fill: #1777E2;
            transition: 0.3s;
        }
    }
}

